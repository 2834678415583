<template>
  <component
    :is="component"
    class="popular-category"
    :class="{
      [`popular-category--${variant}`]: variant,
      'loading': loading,
      'blue': colorVariant === 'blue',
    }"
    :href="isLinkHidden ? undefined : getUrl(item?.section?.path?.value ?? '')"
    :no-prefetch="isLinkHidden ? undefined : true"
    :itemprop="isLinkHidden ? undefined : 'itemListElement'"
    :itemscope="isLinkHidden ? undefined : true"
    :itemtype="isLinkHidden ? undefined : 'https://schema.org/ListItem'"
    @click="isLinkHidden ? navigatePush(item?.section?.path?.value ?? '') : null"
  >
    <template v-if="item">
      <span itemprop="name">
        {{ title }}
      </span>
      <AImage
        v-if="item.image?.url"
        :src="image.desktop"
        :mobile-src="image.mobile"
        :alt="item.image?.alt"
        :from-public="imgFromPublic"
        :width="imgSize"
        :height="imgSize"
        :with-retina="withPixelRatio"
        :loading="isFirstScreen ? 'eager' : 'lazy'"
        :mobile-sizes="variant === 'fix' ? '64px' : '(max-width: 495px) 110px, 276px'"
        :with-webp="withWebp"
      />
      <template v-if="withMark && !isLinkHidden">
        <meta itemprop="position" :content="(index + 1).toString()">
        <link itemprop="url" :href="getUrl(item?.section?.path?.value ?? '')">
      </template>
    </template>
  </component>
</template>

<script lang="ts" setup>
import './PopularCategory.css'
import { computed, defineComponent, resolveComponent } from 'vue'
import { useNavigate } from '@/composables/useNavigate'
import { sizes } from '@/modules/nuxt-api/models/DictionaryCategories'

import type { PropType } from 'vue'
import type { PopularCategoryVariant } from '@/components/atoms/PopularCategory/types'
import type { PopularCategoryExtended } from '@/modules/nuxt-api/models/PopularCategory'

import AImage from '@/components/atoms/Image/AImage.vue'

defineComponent({ name: 'GlobalAPopularCategory' })

const props = defineProps({
  item: {
    type: Object as PropType<PopularCategoryExtended>,
    default: undefined
  },
  variant: {
    type: String as PropType<PopularCategoryVariant>,
    default: undefined
  },
  imgFromPublic: {
    type: Boolean,
    default: false
  },
  loading: {
    type: Boolean,
    default: false
  },
  prevent: {
    type: Boolean,
    default: false
  },
  withPixelRatio: {
    type: Boolean,
    default: false
  },
  withWebp: {
    type: Boolean,
    default: false
  },
  isFirstScreen: {
    type: Boolean,
    default: false
  },
  colorVariant: {
    type: String as PropType<'blue' | undefined>,
    default: undefined
  },
  index: {
    type: Number,
    default: 0
  },
  withMark: Boolean
})

const { getUrl, navigatePush } = useNavigate()
const isLinkHidden = computed(
  () => props.item?.section?.isClosed || props.item?.section?.path?.value?.includes?.('_ll')
)
const component = computed(() => isLinkHidden.value ? 'div' : props.prevent ? 'a' : resolveComponent('NuxtLink'))
const imgSize = computed(() => props.variant === 'fix' ? '64' : '55%')

const image = computed(() => {
  const img = props.item?.image?.url ?? ''
  const { mobile, desktop } = props.variant === 'fix' ? sizes.categoriesFix : sizes.categories
  return {
    mobile: img.replace('%w', mobile.w).replace('%h', mobile.h),
    desktop: img.replace('%w', desktop.w).replace('%h', desktop.h)
  }
})

const title = computed(() => {
  const regex = /0\s/g
  return props.item?.title?.replace?.(regex, '0\u00A0')
})
</script>
